import React from "react"
import tw from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Container from "../components/container"
import ExternalLinkHandler from "../components/externalLinkHandler"
import Layout from "../components/layout"

// Assets
import arch from "../images/icons/arch.svg"
import copay from "../images/icons/copay.svg"
import delivery from "../images/icons/delivery.svg"
import phone from "../images/icons/phone.svg"
import rx from "../images/icons/rx.svg"
import talkBubblesTeal from "../images/icons/talk-bubbles-teal.svg"

const PatientSupport = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "patient-support/hero.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1100
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            quality: 90
          )
        }
      }
    }
  `)

  return (
    <Layout activeLinkIndex={4}>
      <section tw="bg-[#EDF2F4]">
        <Container tw="text-center pt-6 pb-12 px-12 lg:(pt-12 pb-16) xl:(pt-[70px] pb-24)">
          <GatsbyImage
            image={getImage(data.heroImage)}
            css={[
              tw`mx-auto max-w-[232px] lg:(max-w-[293px]) xl:(max-w-[413px])`,
            ]}
            alt="Patient Support for LIVTENCITY. Your Source for Education and Access."
          />
        </Container>
      </section>
      <section>
        <Container tw="text-[#333333] text-center lg:(text-left)">
          <h1 tw="uppercase text-teal text-32 font-light pt-8 pb-[18px] lg:(text-34 pt-11 pb-[22px]) xl:(text-5xl pt-[70px] pb-12)">
            Takeda patient support.{" "}
            <span tw="font-black">We&apos;re here to help.</span>
          </h1>
          <p tw="xl:(text-21 mb-2)">
            If you&apos;ve been prescribed{" "}
            <span tw="uppercase font-bold">Livtencity</span>, Takeda Patient
            Support is here to help you access your medication, find educational
            resources, and understand financial assistance options.
          </p>
          <div tw="font-semibold text-2xl leading-7 pt-2 pb-10 lg:(grid grid-cols-4 gap-10 mx-4 text-base text-center pt-0 pb-7) xl:(text-21 pb-10)">
            <div>
              <div tw="mb-4 mt-8 lg:(mt-7 mb-6) xl:(mb-10)">
                <img
                  alt=""
                  tw="mx-auto w-[59px] xl:(w-[83px])"
                  src={delivery}
                />
              </div>
              <p>
                Help expedite delivery <br tw="hidden lg:(block)" />
                of your medication
              </p>
            </div>
            <div>
              <div tw="mb-4 mt-8 lg:(mt-7 mb-6) xl:(mb-10)">
                <img alt="" tw="mx-auto w-[59px] xl:(w-[83px])" src={rx} />
              </div>
              <p>
                Work with your <br tw="hidden lg:(block)" />
                specialty pharmacy
              </p>
            </div>
            <div>
              <div tw="mb-4 mt-8 lg:(mt-7 mb-6) xl:(mb-10)">
                <img alt="" tw="mx-auto w-[59px] xl:(w-[83px])" src={copay} />
              </div>
              <p>Connect you to co-pay or&nbsp;insurance help</p>
            </div>
            <div>
              <div tw="mb-4 mt-8 lg:(mt-7 mb-6) xl:(mb-10)">
                <img
                  alt=""
                  tw="mx-auto w-[59px] xl:(w-[83px])"
                  src={talkBubblesTeal}
                />
              </div>
              <p>
                Help you understand your condition and{" "}
                <span tw="uppercase">Livtencity</span>
              </p>
            </div>
          </div>
          <div tw="relative -mx-2 before:(absolute z-[-1] left-0 top-[50%] w-full h-[1px] bg-teal) lg:(mx-0)">
            <img
              alt=""
              tw="mx-auto bg-white px-[17px] lg:(w-16 px-2) xl:(w-[91px] px-3)"
              src={arch}
            />
          </div>
          <h2 tw="text-teal text-2xl font-semibold mt-12 mb-8 px-8 lg:(text-xl text-center mt-8 mb-5) xl:(text-27 mt-11 mb-7)">
            There are other ways Takeda Patient Support may be able to help.
          </h2>
          <div tw="lg:(text-center)">
            <ExternalLinkHandler
              button
              violet
              description="patientSupport"
              id="learnMore"
              url="https://www.takedapatientsupport.com/livtencity"
            >
              Learn more
            </ExternalLinkHandler>
          </div>
          <div tw="mt-[52px] flex gap-4 lg:(mx-auto w-fit mt-7)">
            <div tw="shrink-0">
              <img alt="" tw="w-14 lg:(w-11) xl:(w-[62px])" src={phone} />
            </div>
            <address tw="font-light not-italic text-left lg:(mb-2 mt-1) xl:(text-21)">
              <span tw="font-black block lg:(inline)">
                Call Takeda Patient Support
              </span>{" "}
              at{" "}
              <a tw="font-black" href="tel:1-855-268-1825">
                1-855-268-1825
              </a>{" "}
              <br />
              Monday-Friday, 8 AM to 8 PM ET.
            </address>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Patient Support and Resources | LIVTENCITY® (maribavir)"
    description="Learn about patient support available for eligible patients to help access your medication, find educational resources and understand financial assistance options."
  />
)

export default PatientSupport
